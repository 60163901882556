import { FormControl, Grid, InputLabel, MenuItem, Typography } from "@mui/material";
import React from "react";
import { Styled } from "./styles";
import { Select, Input, HelpIcon, Button } from "@dippi/ui";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Forms } from "@/types/form";
import schemas from "@/utils/schemas";
import constant from "@/utils/constant";
import { SELECT_ITEMS } from "@/utils/helpers";
import { useSubmit } from "./hooks";
import { Close } from "@dippi/ui/icons";

interface Props {
    modal: boolean;
    closeModal: () => void;
}

const OrganizationModal: React.FC<Props> = ({ modal, closeModal }) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, errors },
        trigger,
        reset,
    } = useForm<Forms["Organization"]>({
        resolver: yupResolver(schemas.organization),
        defaultValues: {
            industry: "AB",
            country: "GE",
            title: "",
            companyId: "",
            business: "producer",
            email: "",
            phone: "",
            city: "",
            street: "",
            zipCode: "",
        },
    });

    const resetAndClose = () => {
        closeModal();
        reset();
    };

    const onSubmit = useSubmit(resetAndClose);

    const onCloseModal = () => {
        if (isDirty || Object.keys(errors).length > 0) {
            return trigger();
        }
        resetAndClose();
    };

    return (
        <Styled.Modal open={modal} onClose={onCloseModal}>
            <Styled.Form onSubmit={handleSubmit(async form => await onSubmit(form))}>
                <Grid container rowGap={constant.GRID_SPACING}>
                    <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="space-between"
                        marginBottom={2}
                    >
                        <Typography variant="h3" marginBottom={0}>
                            Create your organization
                        </Typography>
                        <Button
                            onClick={resetAndClose}
                            variant="text"
                            sx={{ padding: 0, minWidth: "auto" }}
                        >
                            <Close />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            name="industry"
                            control={control}
                            tabIndex={1}
                            items={SELECT_ITEMS.industries}
                            label="Industry"
                            data-testid="organization-industry-select"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <InputLabel>Country</InputLabel>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <Styled.CountrySelector
                                            value={value}
                                            onChange={onChange}
                                            label="Country"
                                            tabIndex={2}
                                            data-testid="organization-country-select"
                                        >
                                            {SELECT_ITEMS.countries.map(i => {
                                                const isoCode = i.value;
                                                const title = i.label;
                                                return (
                                                    <MenuItem key={isoCode} value={isoCode}>
                                                        <img
                                                            src={`${constant.VITE_CDN_BUCKET_PATH}/static/flags/${isoCode}.png`}
                                                            alt={`${title} flag`}
                                                            height={25}
                                                            width={25}
                                                        />
                                                        <Typography
                                                            fontFamily="Figtree"
                                                            marginLeft="10px"
                                                        >
                                                            {title}
                                                        </Typography>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Styled.CountrySelector>
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            name="business"
                            control={control}
                            tabIndex={3}
                            items={SELECT_ITEMS.business}
                            label="Line of business"
                            data-testid="organization-business-select"
                        />
                    </Grid>
                    <Grid item container spacing={constant.GRID_SPACING}>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="companyId"
                                control={control}
                                label="Company ID"
                                tabIndex={4}
                                testId="organization-company-id-input"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="title"
                                control={control}
                                label="Name"
                                tabIndex={5}
                                testId="organization-company-name-input"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={constant.GRID_SPACING}>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="email"
                                control={control}
                                label="Email"
                                type="email"
                                InputProps={{
                                    endAdornment: (
                                        <HelpIcon
                                            key="organization-email-help"
                                            title="Organization email will be used for invoicing purposes. Change it if you'd like to use different email address."
                                        />
                                    ),
                                }}
                                tabIndex={1}
                                testId="organization-email-input"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="phone"
                                control={control}
                                label="Mobile number"
                                type="tel"
                                tabIndex={2}
                                testId="organization-phone-input"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            name="street"
                            control={control}
                            label="Street"
                            tabIndex={3}
                            testId="organization-street-input"
                        />
                    </Grid>
                    <Grid item container spacing={constant.GRID_SPACING}>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="city"
                                control={control}
                                label="City"
                                tabIndex={4}
                                testId="organization-city-input"
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Input
                                name="zipCode"
                                control={control}
                                label="Post code"
                                tabIndex={5}
                                testId="organization-zip-code-input"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <Button
                            type="submit"
                            fullWidth={true}
                            tabIndex={6}
                            data-testid="organization-submit-button"
                        >
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </Styled.Form>
        </Styled.Modal>
    );
};

export default OrganizationModal;
