import api from "@/config/api";
import { useAdmin } from "@/context/admin";
import { useTable } from "@/context/table";
import { Product } from "@/types/entities";
import { log } from "@/utils/logger";
import { isDefined } from "@/utils/validations";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const useQueryProducts = () => {
    const authenticated = useAdmin(state => state.authenticated);
    const { oid, bid } = useParams();
    const enabled = authenticated && isDefined(oid) && isDefined(bid);

    const { filterModel, paginationModel, sortModel } = useTable();

    const { pageSize, page } = paginationModel;
    const { field, sort } = sortModel;

    const getProducts = async () => {
        try {
            const endpoint = `/organizations/${oid}/brands/${bid}/products?page=${page}&pageSize=${pageSize}&sortField=${field}&sortDirection=${sort}`;

            const data = await api.get<Array<Product>>(endpoint);
            return data;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({
        queryKey: ["products", bid, page, filterModel, sortModel],
        queryFn: getProducts,
        enabled,
    });
};

export default useQueryProducts;
