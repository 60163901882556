import { Box, Chip, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const Styled = {
    Container: styled("div")`
        display: flex;
        flex-direction: column;
        padding: 10px;
        flex: 1;

        ${props => props.theme.breakpoints.down("md")} {
            padding: 10px 0;
        }
    `,
    Table: styled(DataGrid)`
        padding: 25px 25px 10px 25px;
    `,
    Details: styled(Box)`
        display: flex;
        align-items: center;
        gap: 8px;

        & .MuiTypography-root {
            max-width: 250px;
            max-height: 70px;
            word-wrap: break-word;
            overflow: hidden;

            ${props => props.theme.breakpoints.down("xl")} {
                max-width: 150px;
            }

            ${props => props.theme.breakpoints.down("lg")} {
                max-width: 100px;
            }
        }
    `,
    BottleImage: styled(Box)`
        background-color: white;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        background-size: 23%;
        background-repeat: no-repeat;
        background-position: center;
    `,
    Loader: styled("div")`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    `,
    EUChip: styled(Chip)`
        margin-left: 10px;
        font-size: 12px;
        border-radius: 8px;
    `,
};
