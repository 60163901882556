import { Member } from "@/types/utils";
import { LinksSelect } from "@dippi/ui";
import { Divider, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Styled } from "../styles";
import { useMember } from "./hooks";

interface Props {
    id: string;
    field: Member;
}

const MemberRow: React.FC<Props> = ({ field, id }) => {
    const { _id, email, emailVerified, role } = field;

    const { controls, onSaveRole, onSetNewRole, onDelete, onEdit } = useMember();
    const { isEditting, newRole } = controls;

    return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>N/A</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{emailVerified ? "True" : "False"}</TableCell>
            <TableCell sx={{ width: "200px" }}>
                {isEditting ? (
                    <LinksSelect
                        id={`member-${id}`}
                        value={newRole}
                        variant="standard"
                        fullWidth={true}
                        onChange={e => onSetNewRole(e.target.value as Member["role"])}
                        items={[
                            { value: "SUPER_ADMIN", label: "SUPER ADMIN" },
                            { value: "ADMIN", label: "ADMIN" },
                            { value: "MEMBER", label: "MEMBER" },
                        ]}
                    />
                ) : (
                    <Typography variant="inherit">{role}</Typography>
                )}
            </TableCell>
            <TableCell align="center">
                <Styled.List disablePadding={true}>
                    {isEditting ? (
                        <CheckIcon
                            color="success"
                            onClick={() => onSaveRole(_id, newRole)}
                            sx={{ cursor: "pointer", width: 22, marginRight: "5px" }}
                        />
                    ) : (
                        <EditIcon
                            color="action"
                            sx={{ cursor: "pointer", width: 22, marginRight: "5px" }}
                            onClick={() => onEdit(role)}
                        />
                    )}
                    <Divider orientation="vertical" flexItem sx={{ color: "#acb3bb" }} />
                    <DeleteIcon
                        color="error"
                        sx={{ cursor: "pointer", width: 22, marginLeft: "5px" }}
                        onClick={() => onDelete(_id)}
                    />
                </Styled.List>
            </TableCell>
        </TableRow>
    );
};

export default MemberRow;
