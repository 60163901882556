import { create } from "zustand";

interface PaginationModel {
    page: number;
    pageSize: number;
}

interface FilterModel {
    search: string;
}

export interface SortModel {
    field: string;
    sort: "asc" | "desc";
}

interface State {
    paginationModel: PaginationModel;
    filterModel: FilterModel;
    sortModel: SortModel;
}

interface Actions {
    setPagination(paginationModel: Partial<PaginationModel>): void;
    setFilter(filterModel: FilterModel): void;
    setSort(sortModel: SortModel): void;
    reset(): void;
}
const pageSize = 50;

const initialState: State = {
    paginationModel: {
        page: 0,
        pageSize,
    },
    filterModel: {
        search: "",
    },
    sortModel: {
        field: "createdAt",
        sort: "desc",
    },
};

export const useTable = create<State & Actions>((set, get) => ({
    ...initialState,
    setPagination: paginationModel => {
        set({ paginationModel: { ...get().paginationModel, ...paginationModel } });
    },
    setFilter: filterModel => {
        set({ filterModel, paginationModel: { page: 0, pageSize } });
    },
    setSort: sortModel => {
        set({ sortModel, paginationModel: { page: 0, pageSize } });
    },
    reset: () => set(prevState => ({ ...initialState, sortModel: prevState.sortModel })),
}));
