import React from "react";
import { Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Styled } from "./styles";
import { useTable } from "@/context/table";
import { Product } from "@/types/entities";

interface Props {
    data: Product[];
    isFetching: boolean;
}

const Pagination: React.FC<Props> = ({ data, isFetching }) => {
    const { page, pageSize } = useTable(state => state.paginationModel);
    const setPagination = useTable(state => state.setPagination);

    const isLast = data.length < pageSize;

    const getRange = () => {
        if (page === 0 && !data.length) return "0-0";

        const range1 = page * pageSize + 1;
        const range2 = isLast ? page * pageSize + data.length : (page + 1) * pageSize;

        const ranges = `${range1}-${range2}`;

        if (isLast) return ranges;
        return `${ranges} of many`;
    };

    const nextPage = () => {
        const nextPage = page + 1;
        setPagination({ page: nextPage });
    };

    const previousPage = () => {
        if (page === 0) return;

        const prevPage = page - 1;
        setPagination({ page: prevPage });
    };

    return (
        <Styled.Container>
            <Styled.PrevButton
                color="primary"
                disabled={page === 0 || isFetching}
                onClick={previousPage}
            >
                <ChevronLeftIcon />
            </Styled.PrevButton>
            <Typography>{getRange()}</Typography>
            <Styled.NextButton color="primary" disabled={isLast || isFetching} onClick={nextPage}>
                <ChevronRightIcon />
            </Styled.NextButton>
        </Styled.Container>
    );
};

export default Pagination;
