import { useQueryBrands, useQueryMembers, useQueryOrganization } from "@/hooks/queries";
import React from "react";
import constant from "@/utils/constant";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Styled } from "./styles";
import {
    Brands,
    Members,
    SettingsForm,
    SubscriptionForm,
    UsageForm,
} from "@/components/organization";

const Organization: React.FC = () => {
    const { data: organizationData, isLoading: isLoadingOrganization } = useQueryOrganization();
    const { data: brandsData, isLoading: isLoadingBrands } = useQueryBrands();
    const { data: membersData, isLoading: isLoadingMembers } = useQueryMembers();

    if (isLoadingOrganization || isLoadingBrands || isLoadingMembers) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    if (!organizationData || !brandsData || !membersData) {
        return null;
    }

    const { organization, subscription } = organizationData;

    return (
        <Styled.Container>
            <Grid item xs={12}>
                <Typography variant="h1">{organization.title}</Typography>
            </Grid>
            <Grid item container xl={9} gap={constant.GRID_SPACING}>
                <SettingsForm organization={organization} />
                <SubscriptionForm subscription={subscription} />
                <UsageForm usage={subscription.usage} />
                <Members data={membersData} />
                <Brands data={brandsData} organization={organization} />
            </Grid>
        </Styled.Container>
    );
};

export default Organization;
