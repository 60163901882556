import { List, ListItem, Modal, styled } from "@mui/material";

export const Styled = {
    List: styled(List)`
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Item: styled(ListItem)`
        cursor: pointer;
        padding: 4px 0;
        transition: 0.25s linear background-color;
    `,
    Modal: styled(Modal)`
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Form: styled("form")`
        background-color: ${props => props.theme.palette.secondary.main};
        border: 2px solid ${props => props.theme.palette.secondary.light};
        border-radius: 12px;
        width: 400px;
        max-height: 95vh;
        overflow-y: auto;
        padding: 16px 32px;
    `,
};
