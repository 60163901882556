import * as Yup from "yup";
import { typedKeys } from "../validations";
import constant from "../constant";

const industryCodes = constant.INDUSTRIES.map(i => i.code);
const countryCodes = typedKeys(constant.COUNTRIES);

const { BUSINESS } = constant;

export default {
    settings: Yup.object({
        title: Yup.string().required("Title is required").max(50, "Max 50 characters"),
        industry: Yup.string().required("Industry is required").oneOf(industryCodes),
        business: Yup.string().required("Line of business is required").oneOf(constant.BUSINESS),
        companyId: Yup.string().required("ID is required").max(18, "Max 18 characters"),
        email: Yup.string().required("Email is required").email("Invalid email address"),
        phone: Yup.string().required("Phone is required").max(18, "Max 18 characters"),
        address: Yup.object({
            country: Yup.string().required("Country is required").oneOf(countryCodes),
            city: Yup.string().required("City is required").max(24, "Max 24 characters"),
            street: Yup.string().required("Street is required").max(36, "Max 36 characters"),
            zipCode: Yup.string().optional().max(18, "Max 18 characters"),
        }),
    }),
    subscription: Yup.object({
        plan: Yup.string().required("Plan is required").oneOf(constant.SUBSCRIPTION_PLANS),
        media: Yup.object({
            images: Yup.mixed().required("Images is required"),
            storage: Yup.string().required("Storage is required"),
        }),
        brands: Yup.mixed().required("Brands is required"),
        labels: Yup.mixed().required("Labels is required"),
        hasMarketing: Yup.boolean().required(),
        hasPriorityAccess: Yup.boolean().required(),
        productGroups: Yup.array().of(Yup.string().required()).required(),
    }),
    usage: Yup.object({
        labels: Yup.object({
            used: Yup.number(),
            available: Yup.number(),
        }),
        brands: Yup.object({
            used: Yup.number(),
            available: Yup.number(),
        }),
    }),

    organization: Yup.object({
        industry: Yup.string().required("Industry is required").oneOf(industryCodes),
        country: Yup.string().required("Country is required").oneOf(countryCodes),
        business: Yup.string().required("Line of business is required").oneOf(BUSINESS),
        companyId: Yup.string()
            .required("Company ID is required")
            .matches(/^[a-zA-Z0-9_-]+$/, "Invalid company ID")
            .max(18, "Max 18 characters"),
        title: Yup.string().required("Name is required").max(50, "Max 50 characters"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        phone: Yup.string()
            .required("Phone is required")
            .matches(/^[0-9]+$/, "Invalid phone")
            .max(18, "Max 18 characters"),
        street: Yup.string().max(36, "Max 36 characters").required("Street is required"),
        city: Yup.string()
            .required("City is required")
            .matches(/^[a-zA-Z\sÅÄÖåäöÜüØøÆæ\W]+$/i, {
                message: "Invalid city",
            })
            .max(24, "Max 24 characters"),
        zipCode: Yup.string().max(18, "Max 18 characters"),
    }),

    members: Yup.object({
        role: Yup.string().oneOf(["SUPER_ADMIN", "ADMIN", "MEMBER"]),
    }),
};
