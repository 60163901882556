import { Grid, Typography } from "@mui/material";
import { Styled } from "../styles";
import { Button, UncontrolledInput } from "@dippi/ui";
import constant from "@/utils/constant";
import { Dispatch, SetStateAction } from "react";
import { useModal } from "./hooks";

interface Props {
    memberState: { isAdding: boolean; email: string };
    setMemberState: Dispatch<SetStateAction<{ isAdding: boolean; email: string }>>;
}

const MembersModal: React.FC<Props> = ({ memberState, setMemberState }) => {
    
    const { onAdd, onClose } = useModal(memberState, setMemberState);

    return (
        <Styled.Modal open={memberState.isAdding} onClose={onClose}>
            <Styled.Form>
                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="h3" textAlign="center">
                        Add new member
                    </Typography>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="space-between" marginBottom={2}>
                    <UncontrolledInput
                        label="Email"
                        type="email"
                        value={memberState.email}
                        onChange={e =>
                            setMemberState(prevState => ({
                                ...prevState,
                                email: e.target.value,
                            }))
                        }
                        fullWidth={true}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={constant.GRID_SPACING}
                >
                    <Button
                        onClick={() =>
                            setMemberState(prevState => ({ ...prevState, isAdding: false }))
                        }
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button onClick={onAdd} variant="contained">
                        Add
                    </Button>
                </Grid>
            </Styled.Form>
        </Styled.Modal>
    );
};

export default MembersModal;
