import { auth } from "@/config/firebase";
import { useAdmin } from "@/context/admin";
import { log } from "@/utils/logger";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useHeader = () => {
    const logout = useAdmin(state => state.logout);
    const setGlobalLoading = useAdmin(state => state.setGlobalLoading);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const onLogout = async () => {
        try {
            setGlobalLoading(true);
            await auth.signOut(), logout();

            navigate("/sign-in");
        } catch (e) {
            log.error(e);
        } finally {
            setGlobalLoading(false);
        }
    };

    const onLogo = () => navigate("/");
    return {
        open,
        anchorEl,
        onOpenMenu,
        onCloseMenu,
        onLogout,
        onLogo,
    };
};

export { useHeader };
