import constant from "@/utils/constant";
import { initializeApp } from "firebase/app";
import {
    initializeAuth,
    browserLocalPersistence,
    browserPopupRedirectResolver,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const stagingConfig = {
    apiKey: "AIzaSyAAt3aEamX8uQAYKHv_WFSPyZv3qZotF0Q",
    authDomain: constant.DEV ? "dippi-staging.firebaseapp.com" : "portal-staging.dippi.app",
    projectId: "dippi-staging",
    storageBucket: "dippi-staging.appspot.com",
    messagingSenderId: "167799438235",
    appId: "1:167799438235:web:b836d96f280344749fbf3e",
    measurementId: "G-CQEH4W3R57",
};

const productionConfig = {
    apiKey: "AIzaSyAmOme_Bekfgr7Et4V75I_UrDVnTTMSyaI",
    authDomain: "portal.dippi.app",
    projectId: "dippi-production",
    storageBucket: "dippi-production.appspot.com",
    messagingSenderId: "324924424587",
    appId: "1:324924424587:web:da16b3c6f1d210087aef8e",
    measurementId: "G-RRD05Y0FCE",
};

const app = constant.PROD
    ? initializeApp(productionConfig, "production")
    : initializeApp(stagingConfig, "staging");

const auth = initializeAuth(app, {
    persistence: browserLocalPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
});

const cloudStorage = getStorage(app);

const analytics = constant.PROD ? getAnalytics(app) : null;

export { auth, analytics, cloudStorage };
