import { Modal, Select, styled } from "@mui/material";

export const Styled = {
    Modal: styled(Modal)`
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    Form: styled("form")`
        position: relative;
        background-color: ${props => props.theme.palette.secondary.main};
        border: 2px solid ${props => props.theme.palette.secondary.light};
        border-radius: 12px;
        width: 700px;
        max-height: 95vh;
        overflow-y: auto;
        padding: 32px;
    `,
    CountrySelector: styled(Select)`
        .MuiSelect-select {
            display: flex;
            align-items: center;
        }
    `,
};
