import { Box, styled } from "@mui/material";

export const Styled = {
    Container: styled("div")`
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(
            to bottom left,
            ${props => props.theme.palette.primary.main},
            black,
            black
        );
        padding: 20px;
        ${props => props.theme.breakpoints.down("sm")} {
            padding: 20px 0px;
        }
    `,
    Content: styled(Box)`
        margin: 20px 0px;
        width: 500px;
        height: 530px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 0px 5px 0.5px ${props => props.theme.palette.primary.light};
        border-radius: 8px;
        padding: 10px;
        background-color: ${props => props.theme.palette.secondary.dark};
        ${props => props.theme.breakpoints.down("sm")} {
            width: 350px;
        }
        ${props => props.theme.breakpoints.down("xs")} {
            width: 300px;
        }
    `,
    Form: styled("form")`
        padding: 0px 54px;
        display: flex;
        align-items: center;
        flex: 1;
        ${props => props.theme.breakpoints.down("sm")} {
            padding: 0px 14px;
        }
        ${props => props.theme.breakpoints.down("xs")} {
            padding: 0px;
        }
    `,
};
