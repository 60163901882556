import { auth } from "@/config/firebase";
import { log } from "@/utils/logger";
import { User } from "firebase/auth";
import { useEffect, useRef } from "react";
import router from "@/router";
import { useAdmin } from "@/context/admin";

const useLoadApp = () => {
    const logout = useAdmin(state => state.logout);
    const login = useAdmin(state => state.login);

    const setGlobalLoading = useAdmin(state => state.setGlobalLoading);
    const unsubscribe = useRef<() => void>();

    const path = router.state.location.pathname;

    const onAuthStateChanged = async (user: User | null) => {
        try {
            setGlobalLoading(true);
            unsubscribe.current?.();

            if (!user && path === "/sign-up") return;

            if (!user) clearApp();
            if (!user) return router.navigate("/sign-in");
            login();
        } catch (e) {
            log.error(e);
        } finally {
            setGlobalLoading(false);
        }
    };

    const clearApp = () => {
        logout();
    };

    useEffect(() => {
        const sub = auth.onAuthStateChanged(onAuthStateChanged);
        unsubscribe.current = sub;
    }, []);
};

export default useLoadApp;
