import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/query";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import useLoadApp from "./hooks/auth/useLoadApp";
import { ThemeProvider } from "@mui/material";
import { theme, Backdrop, Snackbar } from "@dippi/ui";
import { useAdmin } from "./context/admin";
import "./App.css";
import "simplebar-react/dist/simplebar.min.css";

const App: React.FC = () => {
    useLoadApp();
    const globalLoading = useAdmin(state => state.globalLoading);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
                <Backdrop loading={globalLoading} />
                <Snackbar />
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
