import { Box, Menu, styled } from "@mui/material";

export const Styled = {
    Container: styled("div")`
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 56px;
        padding: 8px 32px;
        background-color: ${props => props.theme.palette.secondary.dark};
        z-index: 2000;
    `,
    Logo: styled("img")`
        width: 78px;
        cursor: pointer;
    `,

    Avatar: styled(Box)`
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 33.61px;
        height: 32px;
        border-radius: 50%;
        background: ${({ theme }) => theme.palette.secondary.light};
    `,

    Dropdown: styled(Box)`
        border: 1px solid ${({ theme }) => theme.palette.secondary.light};
        border-radius: 8px;
        transition:
            color 0.3s ease-in,
            border-color 0.3s ease-in;

        & .MuiButtonBase-root {
            padding: 4px 12px;
            color: ${({ theme }) => theme.palette.secondary.light};
            transition:
                color 0.3s ease-in,
                border-color 0.3s ease-in;

            &:hover {
                color: ${({ theme }) => theme.palette.primary.main};
            }
        }

        & img {
            border-radius: 50%;
        }

        &:hover {
            border: 1px solid ${({ theme }) => theme.palette.primary.main};
            background: none;
            color: ${({ theme }) => theme.palette.primary.main};
        }
    `,

    Menu: styled(Menu)`
        & .MuiPaper-root {
            & li {
                padding: 6px 70px 6px 6px;
            }
        }
    `,

    ItemContent: styled(Box)`
        padding: 0;
        display: flex;
        align-items: center;
        gap: 1px;

        & .MuiTypography-root {
            font-size: 14px;
        }
    `,

    LogoPlaceholder: styled("div")`
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};
