import { Product } from "@/types/entities";

type ClassificationName = {
    title: string;
    subtitle?: string;
};

export const classificationCodeToName: Record<Product["typeName"], ClassificationName> = {
    "10.31.14": { title: "Potatoes", subtitle: "prepared or preserved" },
    "10.39.18": {
        title: "Vegetables, fruit and nuts",
        subtitle: "prepared or preserved by vinegar or acetic acid",
    },
    "10.39.22": { title: "Jams", subtitle: "fruit jellies and fruit or nut puree and pastes" },
    "10.84.12": {
        title: "Sauces",
        subtitle: "mixed condiments or seasonings; mustard flour or meal, prepared mustard",
    },
    "11.01.10": { title: "Distilled alcoholic beverages" },
    "11.02.11": { title: "Sparkling wine" },
    "11.02.12": { title: "Wine" },
    "11.07.19": { title: "Other non alcoholic beverages" },
    "31.09.1": { title: "Furniture" },
};
