import { Box, CircularProgress, IconButton, styled } from "@mui/material";

export const Styled = {
    Spinner: styled(CircularProgress)`
        margin-right: 5%;
    `,
    Container: styled(Box)`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        min-width: 250px;
        max-width: 250px;
    `,
    PrevButton: styled(IconButton)`
        margin-right: 10px;
    `,
    NextButton: styled(IconButton)`
        margin-left: 10px;
    `,
};
