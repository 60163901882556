export default {
    PROD: import.meta.env.MODE === "production",
    STAGING: import.meta.env.MODE === "staging",
    DEV: import.meta.env.DEV,
    VITE_API_URL: import.meta.env.VITE_API_URL,
    VITE_CDN_BUCKET_PATH: import.meta.env.VITE_CDN_BUCKET_PATH,

    GRID_SPACING: 2.5,

    COUNTRIES: {
        AT: "Austria",
        BE: "Belgium",
        BG: "Bulgaria",
        BY: "Belarus",
        HR: "Croatia",
        CY: "Cyprus",
        CZ: "Czechia",
        DK: "Denmark",
        EE: "Estonia",
        FI: "Finland",
        FR: "France",
        GE: "Georgia",
        DE: "Germany",
        GR: "Greece",
        HU: "Hungary",
        IS: "Iceland",
        IE: "Ireland",
        IT: "Italy",
        LV: "Latvia",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MT: "Malta",
        NL: "Netherlands",
        NO: "Norway",
        PL: "Poland",
        PT: "Portugal",
        RO: "Romania",
        RU: "Russia",
        SK: "Slovakia",
        SI: "Slovenia",
        ES: "Spain",
        SE: "Sweden",
        CH: "Switzerland",
        GB: "United Kingdom",
    } as const,

    INDUSTRIES: [
        { title: "Alcoholic beverages industry", code: "AB" },
        { title: "Non-Alcoholic beverages industry", code: "NB" },
        { title: "Food and beverage industry", code: "FB" },
        { title: "Food industry", code: "FO" },
        { title: "Furniture industry", code: "FU" },
    ] as const,

    PRODUCT_GROUPS: [
        "10.31.14",
        "10.39.18",
        "10.39.22",
        "10.84.12",
        "11.01.10",
        "11.02.11",
        "11.02.12",
        "11.07.19",
        "31.09.1",
    ],

    SUBSCRIPTION_PLANS: ["free", "basic", "professional", "enterprise", "custom"] as const,

    BUSINESS: ["producer", "importer", "distributor"] as const,

    ASSETS: {
        dippiLogo:
            import.meta.env.MODE === "production"
                ? "https://cdn.dippi.app/static/dippi-logo.png"
                : "https://cdn-staging.dippi.app/static/dippi-logo.png",
        defaultAvatar:
            import.meta.env.MODE === "production"
                ? "https://cdn.dippi.app/static/default-avatar.png"
                : "https://cdn-staging.dippi.app/static/default-avatar.png",
        defaultImageThumbnail:
            import.meta.env.MODE === "production"
                ? "https://cdn.dippi.app/static/product-default-image-thumbnail.png"
                : "https://cdn-staging.dippi.app/static/product-default-image-thumbnail.png",
        dippiLogo2:
            import.meta.env.MODE === "production"
                ? "https://cdn.dippi.app/static/business-card-dippi-logo.png"
                : "https://cdn-staging.dippi.app/static/business-card-dippi-logo.png",
    },
};
