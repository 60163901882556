import Layout from "@/components/layout";
import { Login, Home, Organization, Products } from "@/pages";
import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

const router = createBrowserRouter(
    createRoutesFromElements(
        <React.Fragment>
            <Route path="/sign-in" element={<Login />} />
            <Route element={<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path="/organization/:oid" element={<Organization />} />
                <Route path="/organization/:oid/brand/:bid/products" element={<Products />} />
            </Route>
        </React.Fragment>,
    ),
);

export default router;
