import { ChevronDownUnsorted, ChevronDown, ChevronUp } from "@dippi/ui/icons";
import { useQueryProducts } from "@/hooks/queries";
import React, { useCallback, useEffect } from "react";
import { useTableHelper } from "./hooks";
import { Styled } from "./styles";
import Pagination from "./pagination";
import { SortModel, useTable } from "@/context/table";
import { isArrayWithData } from "@/utils/validations";
import { GridSortModel } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

const Table: React.FC = () => {
    const { data, isFetching } = useQueryProducts();
    const { paginationModel, sortModel, setSort, reset } = useTable();
    const { rows, columns, getRowSpacing } = useTableHelper();

    const { page, pageSize } = paginationModel;
    const hideFooter = pageSize * page === 0 && !isArrayWithData(data);
    const rowCount = pageSize * page;

    const onSortChange = useCallback((model: GridSortModel) => {
        const sortModel = model[0];

        if (sortModel && sortModel.field && sortModel.sort) {
            const sortModelObject: SortModel = { sort: sortModel.sort, field: sortModel.field };

            return setSort(sortModelObject);
        }
        return setSort({ field: "createdAt", sort: "desc" });
    }, []);

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    return (
        <Styled.Container>
            <Styled.Table
                rows={rows(data)}
                columns={columns()}
                loading={!data && isFetching}
                disableColumnFilter={true}
                disableColumnSelector={true}
                showColumnVerticalBorder={true}
                disableRowSelectionOnClick={true}
                disableColumnMenu={true}
                filterMode="server"
                paginationMode="server"
                sortingMode="server"
                pagination={true}
                hideFooter={hideFooter}
                onRowClick={() => null}
                getRowId={row => row._id}
                hideFooterSelectedRowCount={true}
                columnHeaderHeight={40}
                rowCount={rowCount}
                getRowHeight={() => "auto"}
                initialState={{
                    pagination: { paginationModel },
                    sorting: {
                        sortModel: [sortModel],
                    },
                }}
                onSortModelChange={onSortChange}
                getRowSpacing={getRowSpacing}
                slots={{
                    noRowsOverlay: () => (
                        <Box
                            display="flex"
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h2">No products</Typography>
                        </Box>
                    ),
                    columnSortedAscendingIcon: () => <ChevronUp />,
                    columnSortedDescendingIcon: () => <ChevronDown />,
                    columnUnsortedIcon: () => <ChevronDownUnsorted />,
                    pagination: () => {
                        if (!data) return null;

                        return <Pagination data={data} isFetching={isFetching} />;
                    },
                }}
            />
        </Styled.Container>
    );
};

export default Table;
