import { useQueryOrganizations } from "@/hooks/queries";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { Styled } from "./styles";
import { Button } from "@dippi/ui";
import OrganizationModal from "@/components/home/organization-modal";

const Home: React.FC = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useQueryOrganizations();

    const [modal, setModal] = useState(false);
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    const onSelect = (id: string) => {
        navigate(`/organization/${id}`);
    };

    if (isLoading) {
        return (
            <Styled.Container>
                <CircularProgress />
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <Typography marginBottom={0} variant="h1">
                Organizations
            </Typography>
            {Array.isArray(data) ? (
                <Styled.List>
                    {data.map(i => {
                        return (
                            <Styled.ListItem key={i._id} onClick={onSelect.bind(this, i._id)}>
                                <Typography variant="h4">{i.title}</Typography>
                                <Typography variant="body2">{i.companyId}</Typography>
                            </Styled.ListItem>
                        );
                    })}
                </Styled.List>
            ) : null}
            <Button sx={{ width: "550px", padding: "25px 0px" }} onClick={openModal}>
                Add new organization
            </Button>
            <OrganizationModal modal={modal} closeModal={closeModal} />
        </Styled.Container>
    );
};

export default Home;
