import { Grid, styled } from "@mui/material";

export const Styled = {
    Content: styled(Grid)`
        background-color: ${props => props.theme.palette.secondary.dark};
        border: 1px solid ${props => props.theme.palette.secondary.main};
        border-radius: 16px;
        padding: 32px;
        transition: border-color 0.5s ease, color 0.5s ease;

        &:hover {
            border-color: ${props => props.theme.palette.secondary.light};
        }

        ${props => props.theme.breakpoints.down("md")} {
            border-radius: unset;
        }

        & .MuiTableContainer-root {
            border: 1px solid ${props => props.theme.palette.secondary.light};
            border-radius: 8px;
            background-color: unset;
            width: 700px;

            & .MuiTableHead-root {
                background-color: ${props => props.theme.palette.secondary.main};

                & .MuiTableCell-root {
                    padding: 8px 16px;
                }
            }
        }
    `,
    Brands: styled("ul")`
        display: flex;
        gap: 10px;
        width: 600px;
        flex-direction: column;
    `,
    BrandItem: styled("li")`
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 4px;
        padding: 12px 8px;
        background-color: ${props => props.theme.palette.secondary.main};
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            background-color: ${props => props.theme.palette.secondary.light};
        }
    `,
    BrandLogo: styled("img")`
        width: 35px;
        aspect-ratio: 1.1;
    `,
    BrandLogoPlaceholder: styled("div")`
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: gray;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
};
