import api from "@/config/api";
import { log } from "@/utils/logger";
import { useQuery } from "@tanstack/react-query";
import { isDefined } from "@/utils/validations";
import { useAdmin } from "@/context/admin";
import { useParams } from "react-router-dom";
import { Member } from "@/types/utils";

const useQueryMembers = () => {
    const authenticated = useAdmin(state => state.authenticated);
    const { oid } = useParams();
    const enabled = authenticated && isDefined(oid);

    const getMembers = async () => {
        try {
            const data = await api.get<Array<Member>>(`/organizations/${oid}/members`);
            return data;
        } catch (e) {
            log.error(e);
        }
    };

    return useQuery({ queryKey: ["members", oid], queryFn: getMembers, enabled });
};

export default useQueryMembers;
