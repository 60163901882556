import api from "@/config/api";
import { Member } from "@/types/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { log } from "@/utils/logger";
import { useParams } from "react-router-dom";

interface ControlsProps {
    isEditting: boolean;
    newRole: Member["role"];
}

const useMember = () => {
    const { oid } = useParams<{ oid: string }>();
    const queryClient = useQueryClient();

    const [controls, setControls] = useState<ControlsProps>({
        isEditting: false,
        newRole: "ADMIN",
    });

    const onSaveRole = async (mid: string, newRole: string) => {
        if (!oid) return;

        try {
            await api.put<Member>(`/organizations/${oid}/members/${mid}`, {
                data: { role: newRole },
            });

            setControls(prevState => ({ ...prevState, isEditting: false }));
            queryClient.invalidateQueries({ queryKey: ["members", oid] });
        } catch (error) {
            log.error(error);
        }
    };

    const onDelete = async (mid: string) => {
        if (!oid) return;

        try {
            await api.delete<Member>(`/organizations/${oid}/members/${mid}`);
            queryClient.invalidateQueries({ queryKey: ["members", oid] });
        } catch (error) {
            log.error(error);
        }
    };

    const onSetNewRole = (role: Member["role"]) => {
        setControls(prevState => ({ ...prevState, newRole: role }));
    };

    const onEdit = (role: Member["role"]) => {
        setControls({ newRole: role, isEditting: true });
    };

    return {
        controls,
        onSaveRole,
        onDelete,
        onSetNewRole,
        onEdit,
    };
};

export { useMember };
