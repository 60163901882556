import React, { useState } from "react";
import { Styled } from "../styles";
import { Subscription } from "@/types/entities";
import constant from "@/utils/constant";
import { Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Forms } from "@/types/form";
import { yupResolver } from "@hookform/resolvers/yup";
import schemas from "@/utils/schemas";
import { log } from "@/utils/logger";
import { Input } from "@dippi/ui";
import api from "@/config/api";

interface Props {
    usage: Subscription["usage"];
}

const Form: React.FC<Props> = ({ usage }) => {
    const { oid } = useParams();
    const queryClient = useQueryClient();

    const { labels, brands } = usage;
    const [loading, setLoading] = useState(false);

    const { control, handleSubmit, reset } = useForm<Forms["Usage"]>({
        resolver: yupResolver(schemas.usage),
        values: { labels, brands },
    });

    const onSubmit = async (form: Forms["Usage"]) => {
        try {
            setLoading(true);
            await api.put(`/organizations/${oid}/usage`, { data: form });
            await queryClient.refetchQueries({ queryKey: ["organization", oid], exact: true });
        } catch (e) {
            log.error(e);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Grid
            container
            item
            component="form"
            onSubmit={handleSubmit(async form => await onSubmit(form))}
        >
            <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
                <Grid item xs={12}>
                    <Typography variant="h3">Usage information</Typography>
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="labels.used"
                        label="Used labels"
                        id="usage-used-labels"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="labels.available"
                        label="Available labels"
                        id="usage-available-labels"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="brands.available"
                        label="Available brands"
                        id="usage-available-brands"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xl={1.5} md={2} sm={4} xs={12}>
                    <Input
                        control={control}
                        name="brands.used"
                        label="Used brands"
                        id="usage-used-brands"
                        fullWidth={true}
                    />
                </Grid>
                <Grid item container xs={12} gap={constant.GRID_SPACING}>
                    <Button type="submit" disabled={loading}>
                        Save
                    </Button>
                    <Button type="button" variant="outlined" onClick={() => reset()}>
                        Cancel
                    </Button>
                </Grid>
            </Styled.Content>
        </Grid>
    );
};

export default Form;
