export const isString = (val?: unknown): val is string => {
    return typeof val === "string" && val.length > 0;
};

export const isNumber = (val?: unknown): val is number => {
    return typeof val === "number";
};

export const isObject = (val: unknown): val is object => {
    return typeof val === "object" && val !== null;
};

export const isDefined = <D>(val: D | undefined): val is D => {
    return typeof val !== "undefined";
};

export const hasValue = <D>(val: D | null | undefined): val is D => {
    return typeof val !== "undefined" && val !== null;
};

export const isArrayWithData = <D>(val: D | undefined): val is D => {
    return Array.isArray(val) && val.length > 0;
};

export const isJson = (val: string) => {
    try {
        JSON.parse(val);
        return true;
    } catch (e) {
        return false;
    }
};

export const typedKeys = <O extends object>(obj: O) => {
    return Object.keys(obj) as (keyof O)[];
};
