import React from "react";
import { Styled } from "../styles";
import { Grid, Typography } from "@mui/material";
import constant from "@/utils/constant";
import { isArrayWithData } from "@/utils/validations";
import { useNavigate } from "react-router-dom";
import { Brand, Organization } from "@/types/entities";
import { shorten } from "@/utils/helpers";

interface Props {
    data: Array<Partial<Brand>>;
    organization: Organization;
}

const Brands: React.FC<Props> = ({ data, organization }) => {
    const navigate = useNavigate();

    const onSelectBrand = (id: string) => {
        navigate(`/organization/${organization._id}/brand/${id}/products`);
    };

    return (
        <Styled.Content item container xs={12} gap={constant.GRID_SPACING}>
            <Grid item xs={12}>
                <Typography variant="h2" margin="12px 0px">
                    Brands
                </Typography>
            </Grid>
            {isArrayWithData(data) ? (
                <Styled.Brands>
                    {data.map((i: Partial<Brand>) => {
                        const media = i.media;

                        const title = i.title;

                        const url: string | undefined = media && media.variants?.[3]?.url;

                        return (
                            <Styled.BrandItem
                                key={i._id}
                                onClick={onSelectBrand.bind(this, i._id!)}
                            >
                                {url ? (
                                    <Styled.BrandLogo src={url} alt={i.title} />
                                ) : (
                                    <Styled.BrandLogoPlaceholder>
                                        <Typography
                                            variant="h5"
                                            marginBottom={0}
                                            textTransform="uppercase"
                                        >
                                            {title ? title[0] : "?"}
                                        </Typography>
                                    </Styled.BrandLogoPlaceholder>
                                )}
                                <Typography width="100%" sx={{ wordWrap: "break-word" }}>
                                    {shorten(title!, 35)}
                                </Typography>
                            </Styled.BrandItem>
                        );
                    })}
                </Styled.Brands>
            ) : (
                <Typography>No brands found</Typography>
            )}
        </Styled.Content>
    );
};

export default Brands;
