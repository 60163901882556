import constant from "../constant";
import { log } from "../logger";

const capitalize = (value: string | undefined) => {
    if (!value) return;

    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
};

const shorten = (name: string, maxLength: number) => {
    return name.length > maxLength ? name.substring(0, 35).trim() + "..." : name;
};

const sleep = async (ms: number = 1000) => {
    await new Promise(resolve => setTimeout(resolve, ms));
};

const onHandleLink = async (
    action: "open-eucompliant" | "open-marketing" | "copy-eucompliant" | "copy-marketing",
    eid: string,
) => {
    try {
        const link =
            constant.DEV || constant.STAGING
                ? `https://info-staging.dippi.app/p/${eid}`
                : `https://info.dippi.app/p/${eid}`;

        if (action === "open-eucompliant") {
            window.open(link + "?v=1", "_blank");
        }

        if (action === "open-marketing") {
            window.open(link + "?v=0", "_blank");
        }

        if (action === "copy-eucompliant") {
            await navigator.clipboard.writeText(link + "?v=1");
            log.success("EU compliant link copied!");
        }
        if (action === "copy-marketing") {
            await navigator.clipboard.writeText(link + "?v=0");
            log.success("Marketing link copied!");
        }
    } catch (e) {
        log.error(e);
    }
};

const SELECT_ITEMS = {
    industries: constant.INDUSTRIES.map(i => ({
        label: i.title,
        value: i.code,
    })),
    countries: Object.entries(constant.COUNTRIES).map(i => ({ label: i[1], value: i[0] })),
    business: constant.BUSINESS.map(i => ({
        label: i[0]!.toUpperCase() + i.substring(1),
        value: i,
    })),
    productGroups: constant.PRODUCT_GROUPS.map(i => ({ label: i, value: i })),
    subscriptionPlans: constant.SUBSCRIPTION_PLANS.map(i => ({
        label: i[0]!.toUpperCase() + i.substring(1),
        value: i,
    })),
};

export { capitalize, shorten, sleep, onHandleLink, SELECT_ITEMS };
